import logo from './logo.svg';
import './App.css';
import { Viewer } from './components/viewer/Viewer';
import { Form } from './components/form/Form';
import { useState } from 'react';
import { Price } from './components/form/price';
import { ProjectList } from './components/mrt/projectlist/Projectlist';
import { Zonnepanelen } from './components/zonnepanelen/Zonnepanelen';
import { PriceZonnepanelen } from './components/zonnepanelen/price';
import { ViewerZonnepanelen } from './components/zonnepanelen/Viewer';




function App() {

  

  const [focusValue, setFocusValue] = useState();
  const [formData, setFormData ] = useState();
  const [project, update_project] = useState();

  return (

<div className="container">

<div className="form-container">
<Zonnepanelen updateFormData={setFormData} />
<PriceZonnepanelen formData={formData} update_project={update_project} />

</div>

    {/*}
    <div className="container">

<div class="form-container">
        <ProjectList />
      </div>

   
// VERTEX PROJECT LIST VAN SYNC:

    {*/}
      
      


{ /*}
      // FORM:

      <div class="form-container">
        <div>

          <Form updateFormData={setFormData}  setFocusValue={setFocusValue} />
        </div>
        <Price formData={formData} />
      </div>
    
    */}

    <div className='viewer-container'>
    <ViewerZonnepanelen  project={project} focusValue={focusValue} formData={formData}  />


    </div>
      </div>

    
  );
}

export default App;
