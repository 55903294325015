import { Alert, Button, Chip, CircularProgress, LinearProgress } from "@mui/material";
import { MdOutlineShoppingCartCheckout } from "react-icons/md";
import { addProject, getProject, unsubscribeProjects } from "../firebase/firebase-setup";
import { useEffect, useState } from "react";
import { AlertDialog } from "../dialogs/alertdialog";
import { FaSpinner } from "react-icons/fa";
import { TbFileDownload } from "react-icons/tb";
import { NumericFormat } from "react-number-format";
import { FaRegArrowAltCircleRight } from "react-icons/fa";




export const PriceZonnepanelen = ({ formData, update_project }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [doc, updateDoc]= useState({});
const [price, updatePrice] = useState(0);

  const handleClose = () => {
    unsubscribeProjects();
    setDialogOpen(false);
  };


  useEffect(() => {


    const p = 980 + (((formData?.Aantal_X ) ?? 1) * ((formData?.Aantal_Y)?? 1) *  ((formData?.Spant_Hoogte /1000)?? 1)* 52)

    updatePrice(p);

  },[formData])
  



  const text = <>
  {doc.spinner}
    <p>
    Wij berekenen uw gewenste opstelling.
    </p>
    <p>
    {doc.status}
    </p>
    {/*

    <p>
    {doc.download}
    </p>
    <p>
    {doc.download_pdf}
    </p>
    <p>
    {doc.download_dxf}
    </p>

    */}

</>


  const create_project = () => {
    updateDoc('');
    const id =  "ZO" + Math.round(Math.random() * 10000);
    console.log(formData);
    const order = {
      title: id,
      configuratie: JSON.stringify(formData),
      status: "INITIAL",

    };

    addProject(order,"REQUESTS_ZO").then(() => {

        
        getProject(id,(project) => {
  
            const color =  project.status == 'INITIAL' ? 'warning' : project.status == 'REQUESTED' ? 'warning' : project.status == 'FINISHED' ? 'success' :  project.status == 'ONGOING' ? 'warning' : 'error';
            updateDoc ({title: project.title,
                klant: 'John Doe',
                order: project.title,
                status: <Chip size='small' sx={{color: 'white'}} label={project.status || 'ERROR'} color={color} />,
                date:  (new Date((project?.createdAt?.seconds ?? 0) * 1000)).toLocaleString() ?? '' ,
                download: project.status == 'FINISHED' ? (<p>Download uw 3DS bestand: <a className='nocolorlink' href={project.url}><TbFileDownload /></a></p>) :  project.status == 'REQUESTED' ? '' : '',
                download_pdf: project.status == 'FINISHED' ? (<p>Download uw PDF bestand: <a className='nocolorlink' href={project.pdfurl}><TbFileDownload /></a></p>) :  project.status == 'REQUESTED' ? '' : '',
                download_dxf: project.status == 'FINISHED' ? (<p>Download uw DXF bestand: <a className='nocolorlink' href={project.dxfurl}><TbFileDownload /></a></p>) :  project.status == 'REQUESTED' ? '' : '',
                spinner: project.status == 'REQUESTED' || project.status == 'INITIAL' ? (<p><LinearProgress /></p>) : ''
            })

            if(project.status == 'FINISHED'){
              update_project(project);
              handleClose();

            }
          
        }, "REQUESTS_ZO");

        console.log("besteld !");
    });

    setDialogOpen(true);

  };

  return (
    <div>
      <div className="order-continue">
        <div>
          <h2><NumericFormat value={Math.round(price)} displayType={'text'} thousandSeparator=" " prefix={'€ '} />          </h2>
          <span>Excl. <NumericFormat value={Math.round(price * 0.21)} displayType={'text'} thousandSeparator=" " prefix={'€ '} />  BTW</span>
        </div>
        <Button
          size="large"
          variant="outlined"
          disableElevation
          className="order-button"
          startIcon={<FaRegArrowAltCircleRight />}
          onClick={create_project}
        >
          Bereken
        </Button>
      </div>

      <AlertDialog
        text={text}
        handleClose={handleClose}
        title={"Berekening " + doc.order}
        open={dialogOpen}
      />
    </div>
  );
};
