import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Divider } from '@mui/material';
import { IoChevronDownCircleOutline } from "react-icons/io5";
import { FaRegFilePdf } from "react-icons/fa6";
import { HiCubeTransparent } from "react-icons/hi";
import { LuFileBox } from "react-icons/lu";

const StyledMenu = styled((props) => (

  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 5,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '10px 0',
    },
    '& .MuiMenuItem-root': {
    margin: '6px 0px',
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const downloadFile = (fileName, url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName); // or any file name you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

export const DownloadMenu = ({project}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    };


    const handleClose = (action) => {
        const fileName = project.id;
        switch (action) {
            case 1:
                downloadFile(fileName + '.PDF', project.pdfurl)
                break;

            case 2:
                downloadFile(fileName + '.IFC', project.ifcurl)
                break;

            case 3:
                downloadFile(fileName + '.3DS', project.dsurl)
                break;

            case 4:
                downloadFile(fileName + '.VXZ', project.vxmurl)
                break;

            case 5:
                downloadFile(fileName + '.DWG', project.dwgurl)
                break;
                
            case 6:
                downloadFile(fileName + '.DXF', project.dxfurl)
                break;


                
        }

        setAnchorEl(null);
  };

  return (
<>      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        sx={{marginLeft: '10px'}}
        onClick={handleClick}
        endIcon={<IoChevronDownCircleOutline />}
      >
        Download
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose(1)} disableRipple>
          <FaRegFilePdf />&nbsp; 
          Download PDF
        </MenuItem>
        <MenuItem onClick={() => handleClose(2)} disableRipple>
          <HiCubeTransparent />&nbsp; 
          Download IFC
        </MenuItem>
        <MenuItem onClick={() => handleClose(3)} disableRipple>
          <LuFileBox />&nbsp; 
          Download 3DS
        </MenuItem>
        <MenuItem onClick={() => handleClose(4)} disableRipple>
          <LuFileBox />&nbsp; 
          Download VXM
        </MenuItem>
        <MenuItem onClick={() => handleClose(5)} disableRipple>
          <LuFileBox />&nbsp; 
          Download DWG
        </MenuItem>
        <MenuItem onClick={() => handleClose(6)} disableRipple>
          <LuFileBox />&nbsp; 
          Download DXF
        </MenuItem>
      </StyledMenu>
    </>
  );
}
