import React, { useEffect, useState } from "react";

import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, IconButton, Tab, Tabs } from "@mui/material";
import { RxDimensions } from "react-icons/rx";
import { GiBrickWall } from "react-icons/gi";
import { RiWindowsLine } from "react-icons/ri";
import { FaDoorOpen } from "react-icons/fa";
import { ReactComponent as Koto} from './koto.svg';
import Select from "react-select";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";

import { IoPersonOutline } from "react-icons/io5";
import { calculate_hoogte } from "./common";

const label = { inputProps: { "aria-label": "Switch demo" } };

const SOLAR_PANELS = [
    {
      name: "Monocrystalline Solar Panels (60-cell)",
      dimensions: { width: 1650, height: 990, thickness: 40 },
      poweroutput_min: 270,
      poweroutput_max: 330
    },
    {
      name: "Monocrystalline Solar Panels (72-cell)",
      dimensions: { width: 1950, height: 990, thickness: 40 },
      poweroutput_min: 350,
      poweroutput_max: 400
    },
    {
      name: "Polycrystalline Solar Panels (60-cell)",
      dimensions: { width: 1650, height: 990, thickness: 40 },
      poweroutput_min: 250,
      poweroutput_max: 300
    },
    {
      name: "Polycrystalline Solar Panels (72-cell)",
      dimensions: { width: 1950, height: 990, thickness: 40 },
      poweroutput_min: 325,
      poweroutput_max: 375
    },
    {
      name: "Thin-Film Solar Panels (Cadmium Telluride - CdTe)",
      dimensions: { width: 1200, height: 600, thickness: 20 },
      poweroutput_min: 100,
      poweroutput_max: 150
    },
    {
      name: "Thin-Film Solar Panels (Amorphous Silicon - a-Si)",
      dimensions: { width: 1200, height: 600, thickness: 20 },
      poweroutput_min: 60,
      poweroutput_max: 100
    },
    {
      name: "Bifacial Solar Panels (60-cell)",
      dimensions: { width: 1650, height: 990, thickness: 40 },
      poweroutput_min: 290,
      poweroutput_max: 350
    },
    {
      name: "Bifacial Solar Panels (72-cell)",
      dimensions: { width: 1950, height: 990, thickness: 40 },
      poweroutput_min: 375,
      poweroutput_max: 425
    },
    {
      name: "PERC Solar Panels (60-cell)",
      dimensions: { width: 1650, height: 990, thickness: 40 },
      poweroutput_min: 310,
      poweroutput_max: 360
    },
    {
      name: "PERC Solar Panels (72-cell)",
      dimensions: { width: 1950, height: 990, thickness: 40 },
      poweroutput_min: 380,
      poweroutput_max: 450
    },
    {
      name: "Half-cut Cell Solar Panels (120 half-cut cells)",
      dimensions: { width: 1650, height: 990, thickness: 20 },
      poweroutput_min: 320,
      poweroutput_max: 370
    },
    {
      name: "Half-cut Cell Solar Panels (144 half-cut cells)",
      dimensions: { width: 1950, height: 990, thickness: 20 },
      poweroutput_min: 400,
      poweroutput_max: 450
    }
  ];
  
      

export const Zonnepanelen = ({updateFormData, model}) => {
    const [value, setValue] = useState(0);
    const [selectedPanel, setSelectedPanel] = useState(SOLAR_PANELS[0]);
    const [angle, setAngle] = useState(15);


    const handleChangeTab = (event, newValue) => {
        setValue(newValue);
      };
  
      const updateSelectedPanel = (panel) => {
        console.log(panel)
        setSelectedPanel(panel)
        setFormData({...formData,breedte_X: panel.dimensions.width, Dikte_Paneel: panel.dimensions.thickness, Hoogte_Y: panel.dimensions.height});

      }

  const [formData, setFormData] = useState({
    Aantal_X: 5,
    Aantal_Y: 3,
    breedte_X: 990,
    Hoogte_Y: 1650,
    Dikte_Paneel: 40,
    SP_X: 30,
    SP_Y: 30,
    angle: 15
  });

  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("INITIAL"); // INITIAL , LOADING , DONE

  const switchHightWidth = () => {
    setFormData({...formData,breedte_X: formData.Hoogte_Y, Hoogte_Y: formData.breedte_X});

  }




  useEffect(()=> {
    // add hoogte to formData 
    const h = calculate_hoogte(formData, angle);
    const formDataNew = {...formData, Spant_Hoogte: h, angle: angle}
    console.log(formDataNew);
    updateFormData(formDataNew);
  },[formData, angle])

  const handle_angle = (e) => {
    const angle = e.target.value;
    setAngle(angle);    
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value  });
    console.log(formData);
  };



  return (
    <>
    <div>

        <form >



        <div className='tabbox'>
{/*}

<Tabs
  orientation="vertical"
  role="navigation"
  value={value}
  onChange={handleChangeTab}
  aria-label="Vertical tabs example"
  sx={{ borderRight: 1, width: '90px', borderColor: 'divider' }}
>
  <Tab id='vertical-tab-0' style={{fontSize: "x-large"}} icon={<RxDimensions />}  />
  <Tab id='kvertical-tab-4' style={{fontSize: "x-large"}} icon={<IoPersonOutline />}  />
</Tabs>
*/}


<div className='tabcontent'>

{value === 0 ? <>


            <div className="section">
            <h2>Paneel</h2>


            <div className="form-group">
               <label htmlFor="wall-width">Type paneel:</label>
               
            <Select
                            name="cladding"
                            options={SOLAR_PANELS}
                            value={selectedPanel}
                            onChange={(e) => updateSelectedPanel(e)}
                            getOptionLabel={(option) => (option.name)}
                            getOptionValue={(option) => option}
                            className="roundedcorners"
                        />
</div>

            <div className='group'>
            <div className="form-group">
                <label htmlFor="wall-width">Breedte (X) (mm):</label>
                <input
                  type="number"
                  id="breedte_X"
                  name="breedte_X"
                  value={formData.breedte_X}
                  onChange={handleChange}
                  required
                />
              </div>


              <div className="form-group" style={{width: 'auto'}}>
                <label htmlFor="wall-width">&nbsp;</label>
                <IconButton onClick={switchHightWidth} aria-label="switch">
  <HiOutlineSwitchHorizontal />
</IconButton>
              </div>



              <div className="form-group">
                <label htmlFor="wall-width">Hoogte (Y) (mm):</label>
                <input
                  type="number"
                  id="Hoogte_Y"
                  name="Hoogte_Y"
                  value={formData.Hoogte_Y}
                  onChange={handleChange}
                  required
                />
              </div>
    </div>

                <div className="form-group">
                <label htmlFor="wall-height">Dikte paneel (mm):</label>
                <input
                  type="number"
                  id="Dikte_Paneel"
                  name="Dikte_Paneel"
                  value={formData.Dikte_Paneel}
                  onChange={handleChange}
                  required
                />
              </div>


             {/*    
            <div className="form-group">
                <label htmlFor="wall-height">Frame Lengte:</label>
                <input
                  type="number"
                  id="Frame_L"
                  name="Frame_L"
                  value={formData.Frame_L}
                  onChange={handleChange}
                  required
                />
            </div>
            */}
          </div>

          <div className="section">
            <h2>Opstelling</h2>

            <div className='group'>
            <div className="form-group">
                <label htmlFor="wall-height">Aantal panelen X:</label>
                <input
                  type="number"
                  id="Aantal_X"
                  name="Aantal_X"
                  value={formData.Aantal_X}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="wall-height">Aantal panelen Y:</label>
                <input
                  type="number"
                  id="Aantal_Y"
                  name="Aantal_Y"
                  value={formData.Aantal_Y}
                  onChange={handleChange}
                  required
                />
              </div>

            </div>

            <div className='group'>
            <div className="form-group">
                <label htmlFor="wall-height">Hoek (°):</label>
                <input
                  type="number"
                  id="Spant_Hoogte"
                  name="Spant_Hoogte"
                  value={angle}
                  onChange={handle_angle}
                  required
                />
              </div>

              

             
            </div>


            </div>


<div className="section">
<h2>Speling</h2>

<div className='group'>

<div className="form-group">
  <label htmlFor="wall-height">Speling X (mm):</label>
  <input
    type="number"
    id="SP_X"
    name="SP_X"
    value={formData.SP_X}
    onChange={handleChange}
    required
  />
  </div>

  <div className="form-group">
  <label htmlFor="wall-height">Speling Y (mm):</label>
  <input
    type="number"
    id="SP_Y"
    name="SP_Y"
    value={formData.SP_Y}
    onChange={handleChange}
    required
  />
  </div>
  </div>
  </div>


</> : '' }

{value === 1 ? <>


    <div className="section" 
>
                    <h2><span><IoPersonOutline />&nbsp;Contact gegevens</span></h2>

                    <div className="form-group">
                        <label htmlFor="naam">Naam:</label>
                        <input type="text" id="naam" name="naam" value={formData.naam} onChange={handleChange} required />
                    </div>
                    
                      
                    <div className="form-group">
                            <label htmlFor="height">Adres:</label>
                            <input type="text" id="adres" name="adres" value={formData.adres} onChange={handleChange} required />
                            </div>

                            <div className="form-group">
                            <label htmlFor="btw">BTW-nummer:</label>
                            <input type="text" id="btw" name="btw" value={formData.btw} onChange={handleChange} required />
                            </div>
                     
                </div>
       
</> : '' }
          </div>
</div>


        </form>
        </div>
        </>  );
};


