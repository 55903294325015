export const calculate_hoogte = (formData, angle) => {

    const schuine_zijde = formData.Hoogte_Y * formData.Aantal_Y + (formData.Aantal_Y-1)*formData.SP_Y;
    const hoogte = Math.sin(angle * Math.PI / 180 ) * schuine_zijde;
    console.log("hoogte");
    console.log(hoogte)
    console.log(schuine_zijde);
    console.log(angle);

 
    if(hoogte != NaN){
      return hoogte;
    }else{
        return 1000;
    }

  }


  export const calculate_angle = (formData) => {

    const schuine_zijde = formData.Hoogte_Y * formData.Aantal_Y + (formData.Aantal_Y-1)*formData.SP_Y;
    const hoogte = formData.Spant_Hoogte;

    const hoek = Math.sinh((hoogte / schuine_zijde)) / Math.PI * 180;
    console.log(hoek);
 
    if(hoek != NaN){
      return hoek;
    }else{
        return 45;
    }

  }