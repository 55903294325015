import * as THREE from 'three';

export const lights = (scene) => {
    
    const dirLight = new THREE.DirectionalLight( 0xffffee,0.3 );
    dirLight.position.set( -1,0.90, 3 );
    dirLight.position.multiplyScalar( 70);
    dirLight.name = "dirlight";

   //scene.add( dirLight );
    
    dirLight.castShadow = true;
    
    const d = 300;
    
    dirLight.shadowCameraLeft = -d;
    dirLight.shadowCameraRight = d;
    dirLight.shadowCameraTop = d;
    dirLight.shadowCameraBottom = -d;
    
    dirLight.shadowCameraFar = 3500;
    dirLight.shadowBias = -0.0001;
    dirLight.shadowDarkness = 0.35;

   

    const hemiLight = new THREE.HemisphereLight( 0xddeeff, 0x0f0e0d, 0.02 );
    hemiLight.intensity = 3
    //scene.add( hemiLight );

    // Add ambient light to the scene
    const ambientLight = new THREE.AmbientLight(0xffffff, 5.5); // White light with intensity of 0.5
    scene.add(ambientLight);

    // Step 12: Add directional light to the scene
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5); // White light with intensity of 1
    directionalLight.position.set(-5, 10, 5); // Position the light
    directionalLight.castShadow = true; // Enable shadows
    scene.add(directionalLight);


    const spotLight = new THREE.SpotLight(0xffffff, 2); // White light with intensity 1

    spotLight.position.set(-5, 8, 5); // Set the light's position
spotLight.target.position.set(-5, 0, 5); // Target the origin
scene.add(spotLight);
scene.add(spotLight.target); // Add the target to the scene

//Set up shadow properties for the light
//directionalLight.shadow.mapSize.width = 512; // default
//directionalLight.shadow.mapSize.height = 512; // default
//directionalLight.shadow.camera.near = 0.5; // default
//directionalLight.shadow.camera.far = 500; // default




}