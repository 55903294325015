import * as THREE from "three";
import { SUBTRACTION, REVERSE_SUBTRACTION, Brush, Evaluator, ADDITION, Operation, OperationGroup, HOLLOW_SUBTRACTION, GridMaterial } from 'three-bvh-csg';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import helvetiker from 'three/examples/fonts/helvetiker_regular.typeface.json';
import { LineMaterial } from 'three/addons/lines/LineMaterial.js';
import { add_measure } from "./measure";

const scale = 1/ 1000;

const transparant_blue = new THREE.MeshStandardMaterial({color: 0x0D8AFA, transparent: true, opacity: 0.20, shadowSide: true})


const create_wall = (width, height, thicknes, material) => {
    const w = width *scale;
    const h = height *scale;
    const t = thicknes * scale;
    
    const inside = new Operation( (new THREE.BoxGeometry( w, h, t )).translate(w/2,h/2,0) );
    inside.operation = ADDITION;
    return inside;

}


const create_hole = (x1,y1, width, height) => {
    const w = width *scale;
    const h = height *scale;
    const geometry = (new THREE.BoxGeometry(w,h,0.25)).translate(w/2,h/2,0);
    const inside = new Operation( geometry);
    inside.position.set(x1*scale,y1*scale,0);
    inside.operation = SUBTRACTION;
    return(inside);
}


export const add_wall = (scene, formData) => {
    // can be used for multiple walls
    return add_single_wall(scene, formData, 0);
}



export const add_single_wall = (scene, formData, wall_index) => {
    console.log("wall add")

    // Step 2: Define wall dimensions and thicknesses

    const gyprocThickness =12;
    const metalThickness = formData.frame.dikte;
    const claddingThickness = 15;
    const measurement_z = metalThickness / 2 + claddingThickness + 30;

    // Step 3: Create shapes for the wall with window and door openings
    

    const createWallLayer = (width, height, thicknes, material) => {

        console.log("hier ook")
        const wall = create_wall(width,height,thicknes, material);   

        let holes = [];
        
        formData.deuren.forEach((deur,i) => {
            const door = create_hole(deur.x1,deur.y1,deur.width,deur.height);
            
                const selected_measure = formData.selected_measure;
                const transparant_blue = new THREE.MeshStandardMaterial({color: 0x0D8AFA, transparent: true, opacity: 0.10, shadowSide: true})
                const door_higlight = door.clone();
                door_higlight.operation = ADDITION;
                door_higlight.material = transparant_blue;
                holes.push(door_higlight)


            if(formData.selected == 'deur' && i== formData.selected_index){

                scene.add(door_higlight)

                // add two points:
                const y = (deur.y1 + deur.height / 2) * scale;
                const z = 0.20
                console.log("REDWRAW: " + selected_measure);
                const point = {};
                point['x1'] = []
                point['x1'][0] = new THREE.Vector3(0, y, z);
                point['x1'][1]  = new THREE.Vector3(deur.x1 * scale, y, z);

                point['y1'] = [];
                point['y1'][0] = new THREE.Vector3((deur.x1 + deur.width/2) * scale , deur.y1 * scale, z);
                point['y1'][1] =new THREE.Vector3((deur.x1 + deur.width/2) * scale , 0* scale, z);

                point['height'] = [];
                point['height'][0] = new THREE.Vector3((deur.x1 + deur.width/2) * scale , deur.y1 * scale, z);
                point['height'][1] = new THREE.Vector3((deur.x1 + deur.width/2) * scale , (deur.y1 + deur.height) * scale , z);

                point['width'] = [];
                point['width'][0] = new THREE.Vector3((deur.x1) * scale , (deur.y1 + deur.height / 2 ) * scale, z);
                point['width'][1] = new THREE.Vector3((deur.x1 + deur.width) * scale ,(deur.y1 + deur.height / 2 ) * scale , z);

                console.log(deur);
                add_measure(scene,point[selected_measure][0], point[selected_measure][1], 10)

     

                                
            }

            wall.add(door);
        })


        formData.ramen.forEach((raam,i) => {
            const w = create_hole(raam.x1,raam.y1-raam.height,raam.width,raam.height);

            
                const transparant_blue = new THREE.MeshStandardMaterial({color: 0x0D8AFA, transparent: true, opacity: 0.10, shadowSide: true})
                const door_higlight = w.clone();
                door_higlight.operation = ADDITION;
                door_higlight.material = transparant_blue;
                holes.push(door_higlight)

                if(formData.selected == 'raam' && i== formData.selected_index){

                scene.add(door_higlight)
                const selected_measure = formData.selected_measure;


                // add two points:
                const z = 0.20
                console.log("REDWRAW: " + selected_measure);
                const point = {};
                point['x1'] = []
                point['x1'][0] = new THREE.Vector3(0, (raam.y1 - raam.height / 2) * scale, z);
                point['x1'][1]  = new THREE.Vector3(raam.x1 * scale, (raam.y1 - raam.height / 2) * scale, z);

                point['y1'] = [];
                point['y1'][0] = new THREE.Vector3((raam.x1 + raam.width/2) * scale , raam.y1 * scale, z);
                point['y1'][1] =new THREE.Vector3((raam.x1 + raam.width/2) * scale , 0* scale, z);

                point['height'] = [];
                point['height'][0] = new THREE.Vector3((raam.x1 + raam.width/2) * scale , raam.y1 * scale, z);
                point['height'][1] = new THREE.Vector3((raam.x1 + raam.width/2) * scale , (raam.y1 - raam.height) * scale , z);

                point['width'] = [];
                point['width'][0] = new THREE.Vector3((raam.x1) * scale , (raam.y1 - raam.height / 2 ) * scale, z);
                point['width'][1] = new THREE.Vector3((raam.x1 + raam.width) * scale ,(raam.y1 - raam.height / 2 ) * scale , z);

                console.log(raam);
                add_measure(scene,point[selected_measure][0], point[selected_measure][1], 10)

            }
        

            wall.add(w)
        })


        console.log(holes);
        holes.forEach(hole => {
            hole.geometry.computeBoundingBox();
            hole.boundingBox = new THREE.Box3().setFromObject(hole);
            console.log(hole.boundingBox)
        })


        // Check for collisions
        const transparant_red = new THREE.MeshStandardMaterial({color: 0xed6c02, transparent: true, opacity: 0.15, shadowSide: true})

        for (let i = 0; i < holes.length; i++) {
            for (let j = i + 1; j < holes.length; j++) {
                if (holes[i].boundingBox.intersectsBox(holes[j].boundingBox)) {
                    console.log(`Mesh ${i} collides with Mesh ${j}`);
                    holes[i].material = transparant_red;
                    holes[j].material = transparant_red;
                    scene.add(holes[i])
                    scene.add(holes[j])
                }
            }
        }

        // Check if the holes are inside the wall!

        const w = formData.width *scale;
        const h = formData.height *scale;
        const geometry = (new THREE.BoxGeometry(w,h,0.25)).translate(w/2,h/2,0);
        const wallBox= new THREE.Mesh(geometry,transparant_red);
        wallBox.geometry.computeBoundingBox();
        wallBox.boundingBox = new  THREE.Box3().setFromObject(wallBox);

        console.log(wallBox.boundingBox);
        for (let i = 0; i < holes.length; i++) {
            if (!wallBox.boundingBox.containsBox(holes[i].boundingBox)) {
                console.log(wallBox.boundingBox.containsBox(holes[i].boundingBox))
                console.log(holes[i].boundingBox)
                holes[i].material = transparant_red;
                scene.add(holes[i])

            }
        }

        if(formData.ramen.length == 0 && formData.deuren.length == 0){
            wall.material = material;
            return wall;
        }

        const csgEvaluator = new Evaluator();
        csgEvaluator.useGroups = false;
        const result = csgEvaluator.evaluateHierarchy( wall );
        result.material = material;
        return result;
    }
        


    const w = formData.width * scale ;
    const h = formData.height * scale;

    // Step 6: Load textures and create materials
    const textureLoader = new THREE.TextureLoader();
    const gyprocTexture = textureLoader.load('textures/Plaster002_1K-JPG_Color.jpg');
    gyprocTexture.wrapS = THREE.RepeatWrapping;
    gyprocTexture.wrapT = THREE.RepeatWrapping;
    gyprocTexture.repeat.set( 0.35*5, 0.35*5 );
    gyprocTexture.colorSpace = THREE.SRGBColorSpace;


    const metalTexture = textureLoader.load('textures/Metal011_1K-JPG_Color.jpg');
    metalTexture.wrapS = THREE.RepeatWrapping;
    metalTexture.wrapT = THREE.RepeatWrapping;
    metalTexture.repeat.set( 0.35*5, 0.35*5 );
    metalTexture.colorSpace = THREE.SRGBColorSpace;


    console.log(`textures/${formData.cladding.filename}_Color.jpg`)

    const claddingTexture = textureLoader.load(`textures/${formData.cladding.filename}_Color.jpg`);
    claddingTexture.wrapS = THREE.RepeatWrapping;
    claddingTexture.wrapT = THREE.RepeatWrapping;
    claddingTexture.colorSpace = THREE.SRGBColorSpace;
    //claddingTexture.repeat.set( formData.cladding.size.x*5, formData.cladding.size.y*5  );
    const TEXTURE_SIZE = formData.cladding.size.x;
    claddingTexture.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);
    claddingTexture.needsUpdate = true;

    const claddingNormalMap = textureLoader.load(`textures/${formData.cladding.filename}_NormalGL.jpg`);
    claddingNormalMap.wrapS = THREE.RepeatWrapping;
    claddingNormalMap.wrapT = THREE.RepeatWrapping;
    claddingNormalMap.colorSpace = THREE.SRGBColorSpace;
    claddingNormalMap.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);

    //claddingNormalMap.repeat.set(  formData.cladding.size.x*5, formData.cladding.size.y*5  );


    const claddingRoughnessMap = textureLoader.load(`textures/${formData.cladding.filename}_Roughness.jpg`);
    claddingRoughnessMap.colorSpace = THREE.SRGBColorSpace;
    claddingRoughnessMap.wrapS = THREE.RepeatWrapping;
    claddingRoughnessMap.wrapT = THREE.RepeatWrapping;
    //claddingRoughnessMap.repeat.set(  formData.cladding.size.x*5, formData.cladding.size.y*5  );
    claddingRoughnessMap.repeat.set(w / TEXTURE_SIZE , h / TEXTURE_SIZE);



    const gyprocMaterial = new THREE.MeshStandardMaterial({
        map: gyprocTexture,
        roughness: 1
    });

    const osbMaterial = new THREE.MeshStandardMaterial({
        map: metalTexture,
        roughness: 1
    });

    const claddingMaterial = new THREE.MeshStandardMaterial({
        map: claddingTexture,
        normalMap: claddingNormalMap,
        roughnessMap: claddingRoughnessMap,
        roughness: 1,
        side: THREE.DoubleSide

    });

    const metal = createWallLayer(formData.width,formData.height, metalThickness, osbMaterial); 
    metal.position.z =  (0);
    //scene.add(metal);

    const gyproc = createWallLayer(formData.width,formData.height, gyprocThickness, gyprocMaterial); 
    gyproc.position.z =  (-metalThickness * scale / 2 - gyprocThickness * scale + 0.01);
    //scene.add(gyproc);

    const cladding = createWallLayer(formData.width,formData.height, claddingThickness, claddingMaterial); 
    cladding.position.z = (metalThickness * scale / 2 + 0.01);
    //scene.add(cladding);

    console.log(cladding.position.z);
    console.log(metal.position.z);
    console.log(gyproc.position.z);

    metal.add(gyproc, cladding);

    metal.position.z = wall_index * 3;

    scene.add(metal);


    metal.castShadow = true;
    metal.receiveShadow = true;



    const get_material = (width, height, rotation) => {
        const TEXTURE_SIZE = 5;
        const texture = textureLoader.load(`textures/Bricks028_1K-JPG_Color.jpg`);
        texture.colorSpace = THREE.SRGBColorSpace;
        texture.wrapS = THREE.RepeatWrapping;
        texture.wrapT = THREE.RepeatWrapping;

        texture.repeat.set(width / TEXTURE_SIZE , height / TEXTURE_SIZE);
        texture.rotation = rotation;
        texture.needsUpdate = true;
        const claddingMaterial = new THREE.MeshStandardMaterial({
            map: texture,
            roughness: 1,
            side: THREE.DoubleSide
    
        });


        return claddingMaterial
    }


    return metal;
    /*

    const metal = create_wall(formData.width,formData.height, metalThickness);  
    const gyproc = create_wall(formData.width,formData.height, gyprocThickness);
    const cladding = create_wall(formData.width,formData.height, claddingThickness);

    // Step 7: Create meshes for each layer
    gyproc.material = gyprocMaterial;
    gyproc.castShadow = true;
    gyproc.receiveShadow = true;
    
    metal.material = osbMaterial;
    metal.castShadow = true;
    metal.receiveShadow = true;

    cladding.material = claddingMaterial;
    cladding.castShadow = true;
    cladding.receiveShadow = true;


   

    // Step 8: Position each layer



	
    metal.visible = false;
    gyproc.visible = false;
    cladding.visible = false;


    const 	csgEvaluator = new Evaluator();
    csgEvaluator.useGroups = false;
    const result2 = csgEvaluator.evaluateHierarchy( cladding );
	scene.add( result2 );

    
*/

    /*

    const evaluator = new Evaluator();
    scene.add(evaluator.evaluate( cladding, door, SUBTRACTION, cladding ));
    scene.add(evaluator.evaluate( metal, door, SUBTRACTION, metal ));
    scene.add(evaluator.evaluate( gyproc, door, SUBTRACTION, gyproc ));

    console.log("deur: " + i)


    /// TESETEN
/*
    const brush1 = new Brush( new THREE.BoxGeometry(10,10,10) );
    brush1.updateMatrixWorld();
    brush1.material = claddingMaterial;
    
    const brush2 = new Brush( new THREE.BoxGeometry(10,5,5) );
    brush2.position.y = 0.5;
    brush2.updateMatrixWorld();

    const brush3 = new Brush( new THREE.BoxGeometry(2,20,2) );
    brush3.position.z = 0.5;
    brush3.updateMatrixWorld();
    
    const evaluator = new Evaluator();
    const result = evaluator.evaluate( brush1, brush2, SUBTRACTION );
    const result2 = evaluator.evaluate( result, brush3, SUBTRACTION );

    scene.add(result2);
    
    */

}
