import * as THREE from "three";
import { useEffect, useMemo, useRef, useState } from "react";
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';


import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { defaultForm } from "../form/defaultForm";
import { TDSLoader } from 'three/examples/jsm/loaders/TDSLoader';
import { lights } from "../viewer/lights";
import { Alert, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";

import { PiCubeBold, PiCubeFocusDuotone } from "react-icons/pi";
import { PiFilmScriptDuotone } from "react-icons/pi";

import { PiCornersOutBold } from "react-icons/pi";
import { PiEyeBold } from "react-icons/pi";
import { PiEyeSlashBold } from "react-icons/pi";
import { PiSolarPanelBold } from "react-icons/pi";
import { RiGitPrDraftLine } from "react-icons/ri";
import { FaRegFilePdf } from "react-icons/fa6";
import { Viewer2D } from "../2Dviewer/Viewer2D";
import { DownloadMenu } from "./downloadMenu";
import { draw_solar_panels } from "./DrawSolarPanels";






export const ViewerZonnepanelen = ({focusValue='default', formData = defaultForm, project}) => {


  

  const solarPanelMaterial = useMemo(() => {
    const textureLoader = new THREE.TextureLoader();
    const solarPanelTexture = textureLoader.load(`textures/solar/SolarPanel004_2K-JPG_Color.jpg`);
    solarPanelTexture.colorSpace = THREE.SRGBColorSpace;
    solarPanelTexture.wrapS = THREE.RepeatWrapping;
    solarPanelTexture.wrapT = THREE.RepeatWrapping;
    solarPanelTexture.repeat.set(1,1);
  
      // Maak een materiaal voor het zonnepaneel
       return new THREE.MeshStandardMaterial({
        color: 0x000099, // Donkerblauwe kleur
        metalness: 0.6, // Metaalachtige reflectie
        roughness: 0.4, // Matige ruwheid
        map: solarPanelTexture, // Gebruik de textuur
        side: THREE.DoubleSide,
        //transparent: true,
        //opacity: 0.10,
        //wireframe: true
      });
  }, []);


  const metalMaterial = useMemo(() => {

    const textureLoader = new THREE.TextureLoader();

    const metalTexture = textureLoader.load(`textures/Metal011_1K-JPG_Color.jpg`);
    metalTexture.colorSpace = THREE.SRGBColorSpace;
    metalTexture.wrapS = THREE.RepeatWrapping;
    metalTexture.wrapT = THREE.RepeatWrapping;
    metalTexture.repeat.set(0.001, 0.001);
  
  
      // Maak een materiaal voor metaal
   return new THREE.MeshStandardMaterial({
  color: 0xffffff, // Grijsachtige kleur voor metaal
  metalness: 0.5, // Volledige metaliteit
  roughness: 0.2, // Laag niveau van ruwheid
  map: metalTexture,
  side: THREE.DoubleSide,
  //wireframe: true
  });

  }, []);





  const [alignment, setAlignment] = useState('3d');
  const [viewSolarPanels, setViewSolarPanels] = useState(true);
  const [wireFrameVisible, setWireFrameVisible] = useState(false);

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  function zoomToFit(camera, objects, paddingFactor = 1.2) {
    // Create a bounding box that will encompass all the objects
    const box = new THREE.Box3();

    // Add all the objects' bounding boxes to this box
    objects.forEach(object => {
        const objectBox = new THREE.Box3().setFromObject(object);
        box.expandByObject(object); // Expand the box to include the object
    });

    // Get the center and size of the bounding box
    const boxCenter = new THREE.Vector3();
    const boxSize = new THREE.Vector3();
    box.getCenter(boxCenter);
    box.getSize(boxSize);

    // Add padding to the box size (e.g., paddingFactor of 1.2 gives a 20% margin)
    boxSize.multiplyScalar(paddingFactor);

    // Center the camera on the bounding box center
    const maxSize = Math.max(boxSize.x, boxSize.y, boxSize.z);
    const fitHeightDistance = maxSize / (2 * Math.atan(Math.PI * camera.fov / 360));

    // Position the camera at the center, and pull it back by the required distance
    const direction = new THREE.Vector3()
        .subVectors(camera.position, boxCenter)
        .normalize();
    camera.position.copy(direction.multiplyScalar(fitHeightDistance).add(boxCenter));

    // Make sure the camera looks at the center
    camera.lookAt(boxCenter);

    // Optional: Adjust camera near and far planes
    camera.near = fitHeightDistance / 100;
    camera.far = fitHeightDistance * 10;
    camera.updateProjectionMatrix();
}



  useEffect(() => {
    redraw();
  }, [formData]);

  console.log(formData);

  const downloadPdf = () => {

    const link = document.createElement('a');
    link.href = project.pdfurl;
    link.setAttribute('download', 'yourfile.pdf'); // or any file name you want
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  


  }

  const containerRef = useRef(null);
  





const handeSolarPanels = (event, visible) => {
  setViewSolarPanels(!viewSolarPanels);
}



const handleWireFrame = (event, visible) => {
  setWireFrameVisible(!wireFrameVisible);
}




const onWindowResize = () => {
  if(window){
  console.log("test window resize")
  const newWidth = window.innerWidth;
  const newHeight = window.innerHeight;

  window.renderer.setSize(newWidth ,newHeight)

  window.camera.aspect = newWidth / newHeight
  window.camera.updateProjectionMatrix();
}

}

window.addEventListener("resize", onWindowResize, false)

const add_3dsobject = (scene,object) => {
  object.scale.set(0.001, 0.001, 0.001);
  object.rotation.x = -1*Math.PI / 2; // 90 graden rotatie over de y-as

  // Bereken de bounding box van het object
  const boundingBox = new THREE.Box3().setFromObject(object);
  const center = boundingBox.getCenter(new THREE.Vector3());
  object.position.sub(center);
  object.position.setY(0);

  object.traverse((child) => {
    if (child.isMesh) {
      console.log(child.material); // Log alle materialen
      // Pas een specifieke materiaal aan, bijvoorbeeld door de kleur te veranderen
      if (child.material.name === 'VXLWMAT#3') {
        child.material = solarPanelMaterial;
        child.material.wireframe = wireFrameVisible;
        child.material.visible = viewSolarPanels;


      }else{
        child.material = metalMaterial;
        child.material.wireframe = wireFrameVisible;
        child.material.visible = true;
      }
      
    }
  });

  //scene.add(object);


}

const removeAllMeshes = (scene) => {
  if (!scene || !(scene instanceof THREE.Scene)) {
    console.error('Invalid scene');
    return;
}

  // Step 1: Collect all meshes
  const meshes = [];
  scene.traverse((object) => {
      if (object.isMesh || object.type == "Object3D" ) {
          meshes.push(object);
      }
  });

  // Step 2: Remove all collected meshes
  meshes.forEach((mesh) => {
      //if (mesh.geometry) mesh.geometry.dispose();
      //console.log(mesh)
      //scene.remove(mesh);
      mesh.visible = false;
  });




}

useEffect(()=>{
solarPanelMaterial.wireframe = wireFrameVisible;
solarPanelMaterial.visible = viewSolarPanels;
metalMaterial.wireframe = wireFrameVisible;
metalMaterial.visible = true;

}

,[viewSolarPanels, wireFrameVisible])

const redraw = () => {

  if(window.scene){

    removeAllMeshes(window.scene);
    draw_solar_panels(window.scene,formData,metalMaterial, solarPanelMaterial);

      


    return;

    // nieuwe laden!
    console.log("nieuwe laden!");
    const loader = new TDSLoader();
    loader.load((project? project.dsurl : '/Default.3ds'), (object) => {
      add_3dsobject(window.scene, object);
    });

  }
}


const calculateSceneCenter = (scene) => {
  let x = 0, y = 0, z = 0;
  let count = 0;

  scene.traverse((object) => {
      if (object.isMesh) {
          const position = new THREE.Vector3();
          object.getWorldPosition(position);
          x += position.x;
          y += position.y;
          z += position.z;
          count++;
      }
  });

  if (count === 0) return new THREE.Vector3(); // No objects

  return new THREE.Vector3(x / count, y / count, z / count);
}


useEffect(() => {
  redraw();

  /*
  if(window.scene){
  const allMeshes = [];
  window.scene.traverse(function (object) {
      if (object.isMesh && object.material.map) {
        console.log(object)
          allMeshes.push(object);
      }
  });
  zoomToFit(window.camera,allMeshes, 2.2);  // 20% padding)
}*/


}, [project])

useEffect(() => {
  console.log("new model!! REDRAW!")
  if (typeof window !== 'undefined') {
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true  });
    renderer.gammaOutput = true;
    //renderer.setPixelRatio(window.devicePixelRatio)
    
    renderer.physicallyCorrectLights = true;

    //renderer.toneMappingExposure =0.145; // to allow for very bright scenes.
		
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    const canvas_width = (window.innerWidth / 4) * 3;
    const canvas_height = window.innerHeight;
    //renderer.setSize(, canvas_height);

    renderer.toneMapping = THREE.NoToneMapping;
    renderer.toneMappingExposure = 1;
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    
    
    //renderer.toneMapping = THREE.ACESFilmicToneMapping;
    //renderer.toneMappingExposure = 1.25;
    //renderer.outputEncoding = THREE.sRGBEncoding;



    //renderer.physicallyCorrectLights = true;
    //renderer.shadowMap.enabled = true
    //renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    containerRef.current?.appendChild(renderer.domElement);

    // Add this inside the useEffect hook after initializing the renderer
    if (typeof window !== 'undefined') {
      const geometry = new THREE.BoxGeometry();
      const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });

      // Create an axes helper with size 5
      let axesHelper = new THREE.AxesHelper(5);

      // Add the axes helper to the scene
      //scene.add(axesHelper);

      // Create a grid helper with size 10 and divisions 10
      let gridHelper = new THREE.GridHelper(180, 50, 0xc9c9c9, 0xc9c9c9);
      let gridHelper2 = new THREE.GridHelper(180, 50/3, 0x0d8afa, 0x0d8afa);


      // Add the grid helper to the scene
      scene.add(gridHelper);
      scene.add(gridHelper2);
      scene.fog = new THREE.Fog(0xF5F7F9, 70, 120); // Light gray fog starting at 10 units and fully opaque at 50 units



          lights(scene);


          //let camera = gltf.cameras[10];
          var camera = new THREE.PerspectiveCamera(60, 2, 2, 120);
          const cp = {
            
              x: 15.362053223954387,
              y: 10.748918065847933,
              z: -0.15598939070446605
          
        }
          camera.position.set(cp.x,cp.y,cp.z);
          
          //camera.lookAt(new THREE.Vector3(10,0,0))
                 

          const controls = new OrbitControls(camera, renderer.domElement);
          controls.listenToKeyEvents(window); // optional

          controls.screenSpacePanning = true;

          const ta = {
            x: -7.919450036713001,
            y: -0.13936798461616137,
            z: 16.264218348787402
        }

          controls.target = new THREE.Vector3(ta.x,ta.y,ta.z)
          controls.update();

/*
setInterval(() => {
  console.log(camera.position)
  console.log(controls.target)
},500)*/
          //controls.enableDamping = true;


          // Enable damping (inertia)
          controls.enableDamping = true; // Default is false

          // Set the damping factor (default is 0.25)
          controls.dampingFactor = 0.25; // Lower values give smoother, but slower response

// Adjust the pan speed (default is 1.0)
controls.panSpeed = 0.8; // Lower values make the panning slower and smoother

// Optionally adjust the rotate speed as well if you want smoother rotations
controls.rotateSpeed = 0.7; // Lower values make the rotation slower and smoother


          const composer = new EffectComposer(renderer);
          const renderPass = new RenderPass(scene, camera);
          composer.addPass(renderPass);

          controls.autoRotate = false
          controls.autoRotateSpeed = 0.3;

          // Add this function inside the useEffect hook
          const renderScene = () => {
    
            requestAnimationFrame(renderScene);
            controls.update()
	          //scene.rotation.y = time;
	        //scene.rotation.z = 0.5* ( 1 +  Math.sin( time ) );


    renderer.render(scene, camera);

            //composer.render();

            /*
                // Traverse through all objects in the scene
                scene.traverse(function (object) {
                  //console.log(object)
                    if (object.isMesh && object.name == "text") {
                        // If the object is a text mesh, make it face the camera
                        // Get the camera position and ignore the Y-axis
                        const textPosition = new THREE.Vector3(camera.position.x, camera.position.y, object.position.z);
                        
                        // Make the text only rotate on the XZ plane
                        //            object.lookAt(textPosition);

                        
                    }
                });
*/

            
          };



          // Laad het 3DS-model
          /*
          const loader = new TDSLoader();
          loader.load((project? project.dsurl : '/Default.3ds'), (object) => {
            add_3dsobject(scene, object);
            
          });*/
          
          window.renderer = renderer;
          window.scene = scene;
          window.controls = controls;
          window.camera = camera;
          



          onWindowResize();
          renderScene();

          
          
          



          //scene.updateMatrixWorld(true);
    }
  }
}, [1]);


  return <>
  <div className="viewermenu">
    <div>
     {/*}
  <ToggleButtonGroup

        value={alignment}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment"
      >
       
<ToggleButton value="2d" aria-label="2d">
  <PiCornersOutBold />
</ToggleButton>

<ToggleButton value="3d" aria-label="3d">
<PiCubeBold />   </ToggleButton>
</ToggleButtonGroup>
*/}

<ToggleButton 
  sx={{marginLeft: "0px"}}
  selected={viewSolarPanels}
  value="selected"
  onChange={handeSolarPanels}
  aria-label="visible">
  <PiSolarPanelBold />
</ToggleButton>


<ToggleButton 
  sx={{marginLeft: "10px"}}
  value="selected"
  selected={wireFrameVisible}
  onChange={handleWireFrame}
  aria-label="wireframe">
  <RiGitPrDraftLine />
</ToggleButton>


{ project ? 

< DownloadMenu project={project} />
 : '' }
</div>
      </div>


      <div className="full-screen" style={{display: alignment == '3d' ? 'block' : 'none'}} ref={containerRef}></div>

  </>;
};
