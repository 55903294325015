export const defaultForm = {
    width: 4500,
    height:2750,
    type: 'CLT-280-C7s',
    door1X: '', //
    door1Y: '',
    door1Width: '',
    door1Height: '',

    door2X: '', //
    door2Y: '',
    door2Width: '',
    door2Height: '',

    door3X: '', //
    door3Y: '',
    door3Width: '',
    door3Height: '',


    window3X: '', //
    window3Y: '', //
    window3width: '', //
    window3height: '', //

    window2X: '', //
    window2Y: '', //
    window2width: '', //
    window2height: '', //

    window1X: '', //
    window1Y: '', //
    window1width: '', //
    window1height: '', //

    socket1: false,
    socket1X: '',
    socket1Y:'',


    socket2: false,
    socket2X: '',
    socket2Y:'',


    socket3: false,
    socket3X: '',
    socket3Y:'',


    deur1: false,        deur2: false,        deur3: false,
    raam1: false, raam2: false, raam3: false,

    frigo: true,
    cooker: true,
    sink: true,

    cladding:                                      {displayName: 'Bricks gray', materialName: '3', availableFor: "CLADDING", color: '0xFFFFFF', filename: "Bricks066_1K-JPG", size: {x: 0.65, y: 1.70}, vx_category: 'cladding', vx_name: 'STUCCO-10', price:1.3  },


    deuren: [
     // {width: 1100, height: 2200, x1: 3200, y1: 100},


    ],

    ramen: [
      //{width: 800, height: 1500, x1: 800, y1: 2300},


    ],

frame:       {
    displayName: "STAAL 100",
    dikte: 100,
    availableFor: "FRAME",
    price: 1.1
  },


  selected: "DEUR",
  selected_index:1,
  selected_measure: 'x1'
}