import * as THREE from "three";
import {
  Operation,
  SUBTRACTION,
  Evaluator,
  GridMaterial,
  Brush,
  ADDITION,
  DIFFERENCE,
  REVERSE_SUBTRACTION,
  INTERSECTION,
} from "three-bvh-csg";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader.js";
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';
import helvetiker from 'three/examples/fonts/helvetiker_regular.typeface.json';

const breedte = 80; // Totale breedte van het U-profiel
const hoogte = 60; // Totale hoogte van de flenzen
const dikte = 4; // Dikte van de flenzen en het web
const scale = 0.005;



const calculateDistance = (x1, y1, z1, x2, y2, z2) => {
  return Math.sqrt(
    Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2) + Math.pow(z2 - z1, 2)
  );
};

const get_profile = (lengte) => {
    const shape = new THREE.Shape();

    // Startpunt linksonder van de U-vorm
  shape.moveTo(-breedte / 2, 0);

  // Teken de linkerflens
  shape.lineTo(-breedte / 2, hoogte);

  shape.lineTo(-breedte / 2 + dikte, hoogte);
  shape.lineTo(-breedte / 2 + dikte, dikte);

  // Teken de onderkant (web)
  shape.lineTo(breedte / 2 - dikte, dikte);

  // Teken de rechterflens
  shape.lineTo(breedte / 2 - dikte, hoogte);
  shape.lineTo(breedte / 2, hoogte);
  shape.lineTo(breedte / 2, 0);

  // Sluit de vorm door terug te gaan naar het beginpunt
  shape.lineTo(-breedte / 2, 0);

  const extrudeSettings = {
    steps: 2,
    depth: lengte,
    bevelEnabled: false,
    bevelThickness: 1,
    bevelSize: 1,
    bevelOffset: 0,
    bevelSegments: 1,
  };

  const uProfielGeometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  return uProfielGeometry;
};



const draw_uProfile = (scene, x1, y1, z1, x2, y2, z2, rotationAngle = 0,metalMaterial) => {

    //const axesHelper = new THREE.AxesHelper(1000); // You can adjust the size as needed


    const length = calculateDistance(x1, y1, z1, x2, y2, z2);
    const uProfielGeometry = get_profile(length);
  
    // Create the mesh
    const uProfielMesh = new THREE.Mesh(
      uProfielGeometry,
      //new THREE.MeshStandardMaterial({ color: 0xa0a0a0 })
      metalMaterial
    );
  
  // Position the mesh at the start point
  uProfielMesh.position.set(x1*scale, y1*scale, z1*scale);

  // Calculate the direction vector from start to end point
  const direction = new THREE.Vector3(x2 - x1, y2 - y1, z2 - z1).normalize();

  // Create a temporary vector to use for lookAt
  const tempVector = new THREE.Vector3().copy(direction);

  // Correct the axis orientation
  // Rotate the mesh to correct axis alignment
  // Assuming mesh’s local x-axis should align with the direction vector
  // If x and y are switched, correct the orientation:
  uProfielMesh.lookAt(tempVector.add(uProfielMesh.position));
  
  
  // Rotate around the z-axis by 90 degrees to adjust x and y orientation
  //uProfielMesh.rotateZ(Math.PI / 2);
  uProfielMesh.rotateZ(THREE.MathUtils.degToRad(rotationAngle))

    // Scale the mesh if needed
    uProfielMesh.scale.set(scale,scale,scale);
  
    // Add the mesh to the scene
    //scene.add(uProfielMesh);

    // Add the axesHelper to the parent object to visualize its local origin
    //uProfielMesh.add(axesHelper);

    // Update de geometrieën met de transformaties van de meshes
    uProfielMesh.updateMatrixWorld();
    // Pas de transformaties toe op de geometrieën
    //uProfielMesh.geometry.applyMatrix4(uProfielMesh.matrixWorld);
    return uProfielMesh;

};

const draw_driehoek_peiler = (scene, x1,y1,x2,y2, material) => {
    const p = draw_uProfile(scene,x1,y1,0,x2,y2,0,-90,material);
    return p; 
}


export const draw_driehoek = (scene, lengte_schuine_zijde, hoek, metalMaterial) => {
const zPosition = 0;

// Zet de hoek om naar radialen
const thetaRad = THREE.MathUtils.degToRad(hoek);

// Bereken de lengtes van de rechthoekige zijden
const basis = lengte_schuine_zijde * Math.cos(thetaRad); // Basis
const hoogte = lengte_schuine_zijde * Math.sin(thetaRad); // Hoogte
console.log(basis)
let root;


const p2 = draw_uProfile(scene,0,0,zPosition*1,-basis*1,hoogte*1,zPosition*1,180,metalMaterial);
console.log(zPosition);
console.log(-basis);
console.log(hoogte);


const p3 = draw_uProfile(scene,-basis,hoogte,zPosition,-basis,0,zPosition,-90,metalMaterial);
const p1 = draw_uProfile(scene,-basis,0,zPosition,0,0,zPosition,0,metalMaterial);

// vorm er rond tekenen en afsnijden voor beter effect
const r_box1 = new THREE.Mesh(new THREE.BoxGeometry(20,lengte_schuine_zijde+100,breedte),metalMaterial);
const r_box2 = new THREE.Mesh(new THREE.BoxGeometry(130,2,130),metalMaterial);
const r_box3 = new THREE.Mesh(new THREE.BoxGeometry(2,100,100),metalMaterial);

//r_box3.scale.set(scale,scale,scale)
r_box3.translateX(-basis*scale-1)

r_box2.translateY(-1);

r_box1.translateX(20/2);
r_box1.translateY(1);
const parent = new THREE.Object3D();
parent.add(r_box1);
parent.rotateZ(THREE.MathUtils.degToRad((90-hoek)))
parent.updateMatrixWorld();

p1.updateMatrixWorld();
r_box1.updateMatrixWorld();

p2.updateMatrixWorld();
r_box2.updateMatrixWorld();

p3.updateMatrixWorld();
r_box3.updateMatrixWorld();

p1.geometry.applyMatrix4(p1.matrixWorld);
p2.geometry.applyMatrix4(p2.matrixWorld);
p3.geometry.applyMatrix4(p3.matrixWorld);
r_box1.geometry.applyMatrix4(r_box1.matrixWorld);
r_box2.geometry.applyMatrix4(r_box2.matrixWorld);
r_box3.geometry.applyMatrix4(r_box3.matrixWorld);




// Maak brushes voor beide profielen

root = new Operation( p1.geometry, metalMaterial );
const profiel2 = new Operation( p2.geometry, metalMaterial );
profiel2.operation = ADDITION;
root.add( profiel2 );

const profiel3 = new Operation( p3.geometry, metalMaterial );
profiel3.operation = ADDITION;
root.add( profiel3 );

const inside2 = new Operation( r_box3.geometry, metalMaterial );
inside2.operation = SUBTRACTION;
root.add( inside2 );




const inside = new Operation( r_box1.geometry, metalMaterial );
inside.operation = SUBTRACTION;
root.add( inside );


const inside1 = new Operation( r_box2.geometry, metalMaterial );
inside1.operation = SUBTRACTION;
root.add( inside1 );

// peilers tekenen:

const aantal = Math.round(basis/1100);
const afstand = basis / aantal * -1;
console.log(aantal);
console.log(afstand);


for(let i = 1; i < aantal; i++){ // laatste tekenen we niet
    console.log(i)
    const h = Math.tan(THREE.MathUtils.degToRad(hoek))*afstand*-i
    const p = draw_driehoek_peiler(scene,afstand*i,0,afstand*i,h,metalMaterial);
    scene.add(p);

    p.updateMatrixWorld();
    p.geometry.applyMatrix4(p.matrixWorld);



    // peiler lokaal al correct maken!
    const r_box1 = new Brush(new THREE.BoxGeometry(0.5,breedte*2*scale,breedte*scale),metalMaterial);
    const parent = new THREE.Object3D();
    parent.add(r_box1);
    const y = (afstand*i) / Math.cos(THREE.MathUtils.degToRad(hoek)) * -scale;
    r_box1.translateY(y);
    r_box1.translateX(0.5/2);

    parent.rotateZ(THREE.MathUtils.degToRad((90-hoek)))
    


    const axesHelper = new THREE.AxesHelper(1000); // You can adjust the size as needed
    parent.add(axesHelper)

    parent.updateMatrixWorld();
    r_box1.updateMatrixWorld();

    //scene.add(parent);
    //scene.add(peiler);


    const evaluator = new Evaluator();
    const result = evaluator.evaluate( new Brush(p.geometry), r_box1, SUBTRACTION );
    result.material = metalMaterial;

    const peiler = new Operation( result.geometry, metalMaterial );
    peiler.operation = ADDITION;
    root.add( peiler );


};


const evaluator = new Evaluator();

const result = evaluator.evaluateHierarchy( root );
result.material = metalMaterial;
return(result)
}

const createSolarPanelGeometry = (width, height, thickness) => {
    const geometry = new THREE.BoxGeometry(thickness, height, width);
    return geometry;
  };

const draw_panel = (scene, x,y,z, width, height, thickness, angle, solarPanelMaterial) => {

    // Create the solar panel geometry
    const solarPanelGeometry = createSolarPanelGeometry(width, thickness, height);

    // Create the mesh
    const solarPanelMesh = new THREE.Mesh(
        solarPanelGeometry,
        solarPanelMaterial
    );

    const parent = new THREE.Object3D();

    // Add the box to the parent
    parent.add(solarPanelMesh);
    solarPanelMesh.position.x = -height/2;
    solarPanelMesh.position.y = +thickness/2;
    solarPanelMesh.position.z = width/2;
    parent.rotation.z = THREE.MathUtils.degToRad(-angle);

    // Add the bounding box to the scene
    parent.translateZ(x*scale);
    solarPanelMesh.translateX(-y);


  // Position the solar panel at (x1, y1)
  //parent.position.set(z1*scale,0, x1 * scale); // Assuming z=0 for the panel's placement

  // Rotate the panel around the Z-axis by the specified angle
  //parent.rotation.z = THREE.MathUtils.degToRad(90-angle);
  //solarPanelMesh.translateX(2000*scale)
  //parent.translateX(height/2*scale)

  // Optionally, adjust rotation around other axes if needed
  // solarPanelMesh.rotateX(Math.PI / 2); // Example rotation around X-axis

  // Add the solar panel mesh to the scene
  parent.scale.set(scale,scale,scale);

  scene.add(parent);

    /*
  const boundingBoxHelper = new THREE.BoxHelper(parent, 0xff0000); // Red bounding box
  boundingBoxHelper.update();

  scene.add(boundingBoxHelper);
  
  boundingBoxHelper.update();
const axesHelper = new THREE.AxesHelper(1000); // You can adjust the size as needed

// Add the axesHelper to the parent object to visualize its local origin
parent.add(axesHelper);
*/


}



const add_measure = (scene, point1, point2, text, angleX, angleY, angleZ, offSetZ,offSetY,offSetX) => {
    const transparant_blue = new THREE.MeshStandardMaterial({color: 0x06345E, transparent: true, opacity: 1, shadowSide: true})


  // Create a geometry from the points
  
  const geometry = new THREE.BufferGeometry().setFromPoints([point1, point2]);

  // Create a material for the line

  // Create the line
  // Create the cylinder geometry for the dimension line
  const direction = new THREE.Vector3().subVectors(point2, point1);

  const length = direction.length();
  const cylinderGeometry = new THREE.CylinderGeometry(0.02, 0.02, length, 8);
  const cylinder = new THREE.Mesh(cylinderGeometry, transparant_blue);

  // Position and rotate the cylinder to match the line segment
  cylinder.position.copy(point1).add(direction.multiplyScalar(0.5));
  cylinder.quaternion.setFromUnitVectors(
    new THREE.Vector3(0, 1, 0),
    direction.normalize()
  );
  scene.add(cylinder);

  // Add arrowheads to both points
  // Add arrowheads to both points
  if(length > 0.20){
  createArrowhead(point1, direction, scene);
  createArrowhead(point2, direction.negate(), scene);
}
  // Load a font and add dimension text
  // Load the default font

  
  const loader = new FontLoader();
  const font = loader.parse(helvetiker);

  // Create the text geometry
  const textGeometry = new TextGeometry(text + "", {
    font: font,
    size: 0.55,
    height: 0.001,
  });

  const textMaterial = new THREE.MeshBasicMaterial({
    color: 0x06345E,
    transparent: true,
    opacity: 1,
  });
  const textMesh = new THREE.Mesh(textGeometry, textMaterial);

  // Position the text at the midpoint
  const midPoint = new THREE.Vector3()
    .addVectors(point1, point2)
    .multiplyScalar(0.5);
  textMesh.position.copy(midPoint);
  textMesh.position.y = textMesh.position.y + offSetY;
  textMesh.position.z = textMesh.position.z + offSetZ;
  textMesh.position.x = textMesh.position.x + offSetX;
  textMesh.rotateX(THREE.MathUtils.degToRad(angleX))
  textMesh.rotateY(THREE.MathUtils.degToRad(angleY))
  textMesh.rotateZ(THREE.MathUtils.degToRad(angleZ))
  textMesh.name = "text";
  scene.add(textMesh);

  console.log(textMesh)
  
};


// Function to create arrowheads
const createArrowhead = (position, direction,scene, length = 0.5) => {
    const transparant_blue = new THREE.MeshStandardMaterial({color: 0x06345E, transparent: true, opacity: 1, shadowSide: true})

    const arrowGeometry = new THREE.CylinderGeometry(0.01,0.2, length, 24);

    const arrow = new THREE.Mesh(arrowGeometry, transparant_blue);

  // Position the arrowhead at the given position
  arrow.position.copy(position);
  
  // Align the arrowhead along the direction vector
  arrow.quaternion.setFromUnitVectors(new THREE.Vector3(0, -1, 0), direction);
  
  // Move the arrowhead along the direction to avoid overlap
  arrow.position.add(direction.clone().multiplyScalar(length / 2));
  
  scene.add(arrow);
  }
  

const draw_distance = (formData, scene, height, width, length) => {

    const margin = 0.5;
    const p1 = {x: (-width )*scale - margin, y:0, z: -margin};
    const p2 = {x:(-width)*scale - margin, y:height*scale, z: -margin};
    add_measure(scene, p1,p2, Math.round(height),0,90,0,-0.5,0,0 )

    const p3 = {x:0, y:0, z: -margin};
    const p4 = {x:(-width)*scale - margin, y:0, z: -margin};
    add_measure(scene, p3,p4, Math.round(width),90,180,0,-1,0,0 )


    const p5 = {x:margin, y:0, z: 0};
    const p6 = {x:margin, y:0, z: length * scale};
    add_measure(scene, p5,p6, Math.round(length),90,180,-90,0,0,1 )

}


export const draw_solar_panels = (scene, formData, metalMaterial, solarPanelMaterial) => {
    console.log(formData)
    console.log(solarPanelMaterial);

    const aantal_driehoeken = formData.Aantal_X * 1 + 1;
    const schuine_zijde = formData.Aantal_Y * (formData.Hoogte_Y *1) + (formData.Aantal_Y -1)* (formData.SP_Y);
    const angle = formData.angle;
    console.log(aantal_driehoeken);

    const driehoek_element = draw_driehoek(scene, schuine_zijde,angle, metalMaterial);

    // Aantal_X +1 = aantal driehoeken
    for (let i = 0; i < aantal_driehoeken; i++) {
        const afstand_tussen_driehoeken = (formData.breedte_X *1 + formData.SP_X*1) * i  - formData.SP_X/2;
        let zPos = afstand_tussen_driehoeken * scale;

        if(i==0){
            zPos = (0+breedte/2- formData.SP_X*1)*scale;
        }

        if(i+1 == aantal_driehoeken){
            zPos = (afstand_tussen_driehoeken-breedte/2+formData.SP_X*1)*scale;
        }
        
        //draw_driehoek(scene, schuine_zijde,angle,afstand_tussen_driehoeken, metalMaterial)
        const driehoek = driehoek_element.clone();
        driehoek.translateZ((zPos));
        scene.add(driehoek)

      }


  const panel_width = formData.breedte_X;
  const panel_tichkness = formData.Dikte_Paneel;
  const panel_height = formData.Hoogte_Y;
  const aantal_x = formData.Aantal_X;
  const aantal_y = formData.Aantal_Y;

  
  for (let i = 0; i < aantal_x; i++) {
        for (let j = 0; j < aantal_y; j++) {
            // center point is snapping point!
            const y = j*(panel_height*1 + formData.SP_Y*1)
            const x = i*(panel_width*1) + formData.SP_X * (i);
            draw_panel(scene,x,y,0 ,panel_width,panel_height,panel_tichkness,angle,solarPanelMaterial);
        }
    }

    const axesHelper = new THREE.AxesHelper(100); // You can adjust the size as needed

    // Add the axesHelper to the parent object to visualize its local origin
    //scene.add(axesHelper);  

    //draw_panel(scene,0,0,0 ,900,1500,40,15,solarPanelMaterial);



    // maten nog toevoegen:
    const height = Math.sin(THREE.MathUtils.degToRad(angle)) * schuine_zijde ;
    const width = Math.cos(THREE.MathUtils.degToRad(angle)) * schuine_zijde ;
    const length = aantal_x * (formData.breedte_X*1 + formData.SP_X*1);

    draw_distance(formData, scene, height, width, length);




};
