// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator, orderBy, serverTimestamp, onSnapshot } from 'firebase/firestore'
import { getFunctions,httpsCallable, connectFunctionsEmulator } from "firebase/functions";

import { v4 as uuidv4 } from 'uuid';
import { doc, setDoc, getDoc, collection, query, where, getDocs, limit } from "firebase/firestore"; 

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAFBnq_ljgMBRvs6QqRmQXlAUPNAu3ezxI",
  authDomain: "poc-wandshop.firebaseapp.com",
  projectId: "poc-wandshop",
  storageBucket: "poc-wandshop.appspot.com",
  messagingSenderId: "233937952434",
  appId: "1:233937952434:web:067e43cb96f6f7d8abf3ab",
  measurementId: "G-FC04FJH4X4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

// firebaseApps previously initialized using initializeApp()
//connectFirestoreEmulator(db, '192.168.1.122', 8080);
//connectFunctionsEmulator(functions, "127.0.0.1", 5001);


let unsubscribe;


export const getProject = async (id, callback, collection = "requests_ST") => {
    unsubscribe = onSnapshot(doc(db, collection, id), (doc) => {
        console.log(doc.data());
        callback(({ ...doc.data(), id: doc.id })); // Call the callback function with the data
    });

}


// Function to unsubscribe from the onSnapshot listener
export const unsubscribeProjects = () => {
    if (unsubscribe) {
        unsubscribe();
    }
}

export const getProjects = async (callback,collection = "requests_ST") => {

    const q = query(collection(db, collection), orderBy("createdAt", "desc"), limit(10));

    return onSnapshot(q, (querySnapshot) => {
        const projects = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        callback(projects); // Call the callback function with the data
    });


  }


  export const addProject = async (data, collection = "requests_ST") => {
    const id = uuidv4();
    return setDoc(doc(db, collection, data.title), {...data, url: '', createdAt: serverTimestamp()});    
  }




  export const get_vx_projects = async (callback) => {
    const q = query(collection(db, "vxprojects"), limit(10));
    return onSnapshot(q, (querySnapshot) => {
        const projects = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        callback(projects); // Call the callback function with the data
    });
  }




// refresh with sync!
export const sync_with_sync = httpsCallable(functions, 'sync_with_sync');
